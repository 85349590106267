import { Outlet, Link } from "react-router-dom";
import Box from '@mui/joy/Box';

import './styles/navigator.css'
import NavigationTray from "./NavigationTray";

const Navigator = () => {
    return (
        <Box className='navigator-bar'>
            <Box className='navigator-content'>
                <Link className='link' to="/">Paramore Fan Club</Link>
            </Box>
            <Outlet/>
        </Box>
    )
};

export default Navigator;
