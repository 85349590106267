import './styles/button.css'
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Stack from "@mui/joy/Stack";
import Table from '@mui/joy/Table';
import Typography from "@mui/joy/Typography";

const AllTimeCard = (data) => {
    const user_data = data.users;

    return (
        <Card>
            <CardContent sx={{ maxWidth: '340px', maxHeight: '315px' }}>
                <Box>
                    <Stack direction="column" spacing={-1} sx={{ width: '100%' }}>
                    <Typography level='title-lg' sx={{ textAlign: 'left'}}>
                        Most Active Users
                    </Typography>
                    <Typography level='body-sm' sx={{ textAlign: 'left'}}>
                        All Time
                    </Typography>
                </Stack>
                </Box>
                <Box sx={{ overflow: 'auto' }}>
                    <Table stickyHeader hoverRow size="sm" stripe="even" sx={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Messages</th>
                            </tr>
                        </thead>
                        <tbody>
                            {user_data.map((user) => (
                                <tr key={user['fan_id']}>
                                    <td sx={{ textAlign: 'left' }}>{user['fan']}</td>
                                    <td>{user['message_count']}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Box>
            </CardContent>
        </Card>
    );
}

export default AllTimeCard;