import React, {useState, useEffect} from "react";
import axios from "axios";
import BillboardCard from "../components/BillboardCard";
import PieChartCard from '../components/PieChartCard'
import './styles/home.css';

import CircularProgress  from "@mui/joy/CircularProgress";
import Stack from "@mui/joy/Stack";
import AllTimeCard from "../components/AllTimeCard";

function Home() {
    const [users, setUsers] = useState([]);
    const [active, setActive] = useState({});
    const [total, setTotal] = useState({});
    const [activeMessengers, setActiveMessengers] = useState([]);
    const [activeChannels, setActiveChannels] = useState([]);
    const [totalMessages, setTotalMessages] = useState({});

    const [loadingUsers, setLoadingUsers] = useState(false);
    const [loadingActive, setLoadingActive] = useState(false);
    const [loadingTotal, setLoadingTotal] = useState(false);
    const [loadingTotalMessages, setLoadingTotalMessages] = useState(false);
    const [loadingActiveChannels, setLoadingActiveChannels] = useState(false);
    const [loadingActiveMessengers, setLoadingActiveMessengers] = useState(false);


    useEffect(() => {
        // Users - Top 10
        axios.get("https://pfc-nmgfbpc62q-uc.a.run.app/users/top10")
            .then(response => {
                setUsers(response.data);
                setLoadingUsers(true);
            })
            .catch(error => console.error(error));

        // Users - Active
        axios.get("https://pfc-nmgfbpc62q-uc.a.run.app/users/active")
            .then(response => {
                setActive(response.data['ActiveUsers']);
                setLoadingActive(true);
            })
            .catch(error => console.error(error));

        // Users - Total
        axios.get("https://pfc-nmgfbpc62q-uc.a.run.app/users/total")
            .then(response => {
                setTotal(response.data['TotalUsers']);
                setLoadingTotal(true);
            })
            .catch(error => console.error(error));

        // Messages - Active Channels
        axios.get("https://pfc-nmgfbpc62q-uc.a.run.app/messages/activeChannel")
            .then(response => {
                setActiveChannels(response.data);
                setLoadingActiveChannels(true);
            })
            .catch(error => console.error(error));

        // Messages - Active Messengers
        axios.get("https://pfc-nmgfbpc62q-uc.a.run.app/messages/activeMessenger")
            .then(response => {
                setActiveMessengers(response.data);
                setLoadingActiveMessengers(true);
            })
            .catch(error => console.error(error));

        // Messages - Total
        axios.get("https://pfc-nmgfbpc62q-uc.a.run.app/messages/total")
            .then(response => {
                setTotalMessages(response.data['TotalMessages']);
                setLoadingTotalMessages(true);
            })
            .catch(error => console.error(error));
    }, []);

    return (
        <div className="App">
            <header className="App-header">
                <h1 className='App-Title'>Paramore Fan Server</h1>

                { (loadingActive && loadingUsers && loadingTotal && loadingActiveMessengers && loadingActiveChannels && loadingTotalMessages) ?
                    (
                        <Stack direction='row' spacing={1}>
                            <Stack spacing={1}>
                                <PieChartCard name='Most Active Users' time='1 month' chartData={activeMessengers}/>
                                <PieChartCard name='Most Active Channel' time='1 month' chartData={activeChannels}/>
                            </Stack>
                            <Stack direction='column' spacing={1}>
                                <Stack direction='row' spacing={1}>
                                    <BillboardCard name='Messages per Minutes' time='1 month' value={(totalMessages/43800).toPrecision(2)}/>
                                    <BillboardCard name='Messages Sent' time='1 month' value={totalMessages}/>
                                </Stack>
                                <Stack direction='row' spacing={1}>
                                    <BillboardCard name='Active Users' time='1 hour' value={active}/>
                                    <BillboardCard name='Total Users' time={null} value={total}/>
                                </Stack>
                                <AllTimeCard name='Total Users' time='2 month' users={users}/>
                            </Stack>
                        </Stack>
                    )
                    :
                    (<CircularProgress size="lg" value={20} variant="soft"/>)
                }
            </header>
        </div>
    );
}

export default Home;
