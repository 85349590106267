const NotFound = () => {
    return (
        <div className="App">
            <header className="App-header">
                <h1 className='App-Title'>404</h1>

                <p>
                    Edit <code>src/pages/NotFound.js</code> and save to reload.
                </p>
            </header>
        </div>
    );
};

export default NotFound;